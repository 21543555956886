// TODO: We need to add defaults to these properties.
export class GeneralSettingsMobile {
    /**
     * The label for the resolve conflict button.
     */
    resolveConflictButtonLabel!: string;

    /**
     * The length of the offline PIN.
     */
    offlinePinLength!: number;

    /**
     * The minimum length of the offline PIN.
     */
    minOfflinePinLength!: number;

    /**
     * The maximum length of the offline PIN.
     */
    maxOfflinePinLength!: number;

    /**
     * Indicates whether single sign-on (SSO) is enabled.
     */
    ssoEnabled!: boolean;
}
