// TODO: We should add defaults to these properties.
export class EmailSettings {
    /**
     * The complexity configuration for the password used for sharing emails.
     */
    configureComplexityForPasswordForShareEmail: string = "";

    /**
     * The validation message for the password complexity configuration used for sharing emails.
     */
    configureValidationMessageForPasswordComplexity: string = "";

    /**
     * The regular expression configuration for validating shared email addresses.
     */
    configureEmailRegexForShareEmail: string = "";

    /**
     * The validation message for the email address regular expression configuration used for sharing emails.
     */
    configureValidationMessageForEmailRegex: string = "";

    /**
     * Indicates whether Multi-Factor Authentication (MFA) is enabled for sharing emails.
     */
    mfaForShare: boolean = false;
}
