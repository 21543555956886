import { Injectable, inject } from "@angular/core";
import { SystemSettingsDTO } from "../../data/models/dto/system-settings.dto";
import { firstValueFrom, map } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { LOGGER } from "../../../logging/providers/logger.provider";
import { ILogger } from "../../../logging/models/logger.model";
import { SystemSettings } from "../../core/domain/system-settings.model";
import { SystemSettingsMapper } from "../../data/transformations/system-settings.mapper";
import { SystemSettingsLocalRepository } from "./system-settings-local.repository";

@Injectable({
    providedIn: "root",
})
export class SystemSettingsRemoteRepository {
    private _httpClient: HttpClient = inject(HttpClient);
    private _logger: ILogger = inject(LOGGER);
    private _systemSettingsMapper: SystemSettingsMapper =
        inject(SystemSettingsMapper);
    private systemSettingsLocalRepository: SystemSettingsLocalRepository =
        inject(SystemSettingsLocalRepository);

    /**
     * Get system settings from Remote server and saving to indexed DB
     * @returns Promise<SystemSettings>
     */
    async getSystemSettings(): Promise<SystemSettings> {
        const start = performance.now();
        this._logger.debug("Fetching system settings from server");

        const systemSettings = await firstValueFrom(
            this._httpClient
                .get<SystemSettingsDTO>(
                    environment.routes.mCase.routes.systemSettings.url
                )
                .pipe(
                    map((systemSettingsDTO) =>
                        this._systemSettingsMapper.mapFrom(systemSettingsDTO)
                    )
                )
        );

        this._logger.debug("Saving system settings to Indexed DB");
        await this.systemSettingsLocalRepository.saveSystemSettings(
            systemSettings
        );

        const end = performance.now();
        this._logger.debug(
            `Fetching system settings from server completed in ${end - start} milliseconds.`
        );

        return systemSettings;
    }
}
