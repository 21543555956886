import { Injectable } from "@angular/core";
import { Mapper } from "../../../base/mapper";
import { DateTimeFormatSettings } from "../../core/domain/date-time-format-settings.model";
import { DateTimeFormatDTO } from "../models/dto/date-time-format-settings.dto";

@Injectable({
    providedIn: "root",
})
/**
 * Maps between DateTimeFormatDTO and DateTimeFormatSettings objects.
 */
export class DateTimeFormatSettingsMapper extends Mapper<
    DateTimeFormatDTO,
    DateTimeFormatSettings
> {
    /**
     * Maps from DateTimeFormatDTO to DateTimeFormatSettings.
     * @param dto DateTimeFormatDTO object.
     * @returns DateTimeFormatSettings object.
     */
    override mapFrom(dto: DateTimeFormatDTO): DateTimeFormatSettings {
        // Since they have same properties, use spread operator to copy all properties
        return Object.assign(new DateTimeFormatSettings(), { ...dto });
    }
    /**
     * Maps from DateTimeFormatSettings to DateTimeFormatDTO.
     * @param domain DateTimeFormatSettings object.
     * @returns DateTimeFormatDTO object.
     */
    override mapTo(domain: DateTimeFormatSettings): DateTimeFormatDTO {
        // Since they have same properties, use spread operator to copy all properties
        return Object.assign(new DateTimeFormatDTO(), { ...domain });
    }
}
