import { EmailSettingsJSON } from "../response/email-settings.response";

/**
 * Represents the Email Settings Data Transfer Object (DTO).
 * This class is used to store and manipulate email settings data.
 */
export class EmailSettingsDTO {
    /**
     * The complexity configuration for the password used for sharing emails.
     */
    configureComplexityForPasswordForShareEmail: string = "";

    /**
     * The validation message for the password complexity configuration used for sharing emails.
     */
    configureValidationMessageForPasswordComplexity: string = "";

    /**
     * The regular expression configuration for validating shared email addresses.
     */
    configureEmailRegexForShareEmail: string = "";

    /**
     * The validation message for the email address regular expression configuration used for sharing emails.
     */
    configureValidationMessageForEmailRegex: string = "";

    /**
     * Indicates whether Multi-Factor Authentication (MFA) is enabled for sharing emails.
     */
    mfaForShare: boolean = false;

    /**
     * Creates an instance of the EmailSettingsDTO class.
     * @param emailSettingsJSON Optional JSON object containing email settings data.
     */
    constructor(emailSettingsJSON?: EmailSettingsJSON) {
        if (emailSettingsJSON) {
            this.configureComplexityForPasswordForShareEmail =
                emailSettingsJSON.ConfigureComplexityForPasswordForShareEmail;
            this.configureValidationMessageForPasswordComplexity =
                emailSettingsJSON.ConfigureValidationMessageForPasswordComplexity;
            this.configureEmailRegexForShareEmail =
                emailSettingsJSON.ConfigureEmailRegexForShareEmail;
            this.configureValidationMessageForEmailRegex =
                emailSettingsJSON.ConfigureValidationMessageForEmailRegex;
            this.mfaForShare = emailSettingsJSON.MFAforSHARE;
        }
    }
}
