import { SystemSettingsDTO } from "../../data/models/dto/system-settings.dto";
import { Injectable, inject } from "@angular/core";
import { DataAccess } from "../../../../infrastructure/data-access/data-access";
import { DB_TABLES } from "../../../data-access/enums/table-list.enum";
import { DATA_ACCESS_SERVICE } from "../../../data-access/providers/data-access.provider";
import { SystemSettings } from "../../core/domain/system-settings.model";
import { SystemSettingsMapper } from "../../data/transformations/system-settings.mapper";

/**
 * Service serves as offline proxy operation that is
 * responsible for handling the local storage of system settings.
 */
@Injectable({
    providedIn: "root",
})
export class SystemSettingsLocalRepository {
    private _dataAccess: DataAccess = inject(DATA_ACCESS_SERVICE);
    private _systemSettingsMapper: SystemSettingsMapper =
        inject(SystemSettingsMapper);

    /**
     * Get system settings from IndexedDB.
     * @param systemSettingsDTO - The system settings to be set.
     * @returns A promise that resolves to void.
     */
    async getSystemSettings(): Promise<SystemSettings[]> {
        const systemSettingsDTO =
            await this._dataAccess.readAll<SystemSettingsDTO>(
                DB_TABLES.SYSTEM_SETTINGS
            );

        // mapping from DTO to domain model
        return systemSettingsDTO.map((x) =>
            this._systemSettingsMapper.mapFrom(x)
        );
    }

    /**
     * Saves the system settings to IndexedDB.
     * @param systemSettings - The system settings to be set.
     * @returns A promise of system setting domain object
     */
    async saveSystemSettings(
        systemSettings: SystemSettings
    ): Promise<SystemSettings> {
        const systemSettingsDTO: SystemSettingsDTO =
            this._systemSettingsMapper.mapTo(systemSettings);

        await this._dataAccess.create<SystemSettingsDTO>(
            DB_TABLES.SYSTEM_SETTINGS,
            systemSettingsDTO
        );

        return systemSettings;
    }
}
