import { GeneralSettingsMobileJSON } from "../response/general-settings.response";

/**
 * Represents the data transfer object for general mobile settings.
 */
export class GeneralSettingsMobileDTO {
    resolveConflictButtonLabel!: string;
    offlinePinLength!: number;
    minOfflinePinLength!: number;
    maxOfflinePinLength!: number;
    ssoEnabled!: boolean;

    /**
     * Creates an instance of GeneralSettingsMobileDTO.
     * @param generalSettingsMobileJSON - The JSON object containing the general mobile settings data.
     */
    constructor(generalSettingsMobileJSON?: GeneralSettingsMobileJSON) {
        if (generalSettingsMobileJSON) {
            this.resolveConflictButtonLabel =
                generalSettingsMobileJSON.resolveConflictButtonLabel;
            this.offlinePinLength = generalSettingsMobileJSON.offlinePinLength;
            this.minOfflinePinLength =
                generalSettingsMobileJSON.minOfflinePinLength;
            this.maxOfflinePinLength =
                generalSettingsMobileJSON.maxOfflinePinLength;
            this.ssoEnabled = generalSettingsMobileJSON.ssoEnabled;
        }
    }
}
