import { MobileSettingsJSON } from "../response/mobile-settings.response";

/**
 * Represents the data transfer object for mobile settings.
 */
export class MobileSettingsDTO {
    /**
     * Indicates whether to keep records on the device.
     */
    keepRecordsOnDevice!: boolean;

    /**
     * The message displayed when records are on the device.
     */
    recordsOnDeviceMessage!: string;

    /**
     * The text for the pre-short sync message.
     */
    preShortSyncMessageText!: string;

    /**
     * The text for the post-short sync and pre-long sync message.
     */
    postShortSyncPreLongSyncMessageText!: string;

    /**
     * Indicates whether to confirm long sync.
     */
    confirmLongSync!: boolean;

    /**
     * The duration for auto long sync in minutes.
     */
    autoLongSync!: number;

    /**
     * Indicates whether to display the offline toggle.
     */
    displayOfflineToggle!: boolean;

    /**
     * Indicates whether to show the user settings page.
     */
    showUserSettingsPage!: boolean;

    /**
     * The message displayed for auto long sync.
     */
    autoLongSyncMessage!: string;

    /**
     * The message displayed when the app is updated.
     */
    appUpdatedMessage!: string;

    /**
     * The message displayed in offline mode.
     */
    offlineModeMessage!: string;

    /**
     * The message displayed in online mode.
     */
    onlineModeMessage!: string;

    /**
     * The message displayed for force offline toggle.
     */
    forceOfflineToggleMessage!: string;

    /**
     * The message displayed when there is an error retrieving a record.
     */
    errorRetrievingRecordMessage!: string;

    /**
     * Count of drafts created older than or equal to draftsAgeDays to be to be shown in drafts dialog
     */
    draftsAgeDays: number = 3;

    /**
     * Initializes a new instance of the MobileSettingsDTO class.
     * @param mobileSettingsJSON - The JSON object containing mobile settings data.
     */
    constructor(mobileSettingsJSON?: MobileSettingsJSON) {
        if (mobileSettingsJSON) {
            this.keepRecordsOnDevice = mobileSettingsJSON.keepRecordsOnDevice;
            this.recordsOnDeviceMessage =
                mobileSettingsJSON.recordsOnDeviceMessage;
            this.preShortSyncMessageText =
                mobileSettingsJSON.preShortSyncMessageText;
            this.postShortSyncPreLongSyncMessageText =
                mobileSettingsJSON.postShortSyncPreLongSyncMessageText;
            this.confirmLongSync = mobileSettingsJSON.confirmLongSync;
            this.autoLongSync = mobileSettingsJSON.autoLongSync;
            this.displayOfflineToggle = mobileSettingsJSON.displayOfflineToggle;
            this.showUserSettingsPage = mobileSettingsJSON.showUserSettingsPage;
            this.autoLongSyncMessage = mobileSettingsJSON.autoLongSyncMessage;
            this.appUpdatedMessage = mobileSettingsJSON.appUpdatedMessage;
            this.offlineModeMessage = mobileSettingsJSON.offlineModeMessage;
            this.onlineModeMessage = mobileSettingsJSON.onlineModeMessage;
            this.forceOfflineToggleMessage =
                mobileSettingsJSON.forceOfflineToggleMessage;
            this.errorRetrievingRecordMessage =
                mobileSettingsJSON.errorRetrievingRecordMessage;
        }
    }
}
