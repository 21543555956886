import { Injectable } from "@angular/core";
import { Mapper } from "../../../base/mapper";
import { MobileSettings } from "../../core/domain/mobile-settings.model";
import { MobileSettingsDTO } from "../models/dto/mobile-settings.dto";

@Injectable({
    providedIn: "root",
})
/**
 * Mapper class for mapping between MobileSettingsDTO and MobileSettings objects.
 */
export class MobileSettingsMapper extends Mapper<
    MobileSettingsDTO,
    MobileSettings
> {
    /**
     * Maps from MobileSettingsDTO to MobileSettings.
     * @param dto MobileSettingsDTO object.
     * @returns MobileSettings object.
     **/
    override mapFrom(dto: MobileSettingsDTO): MobileSettings {
        // Since they have same properties, use spread operator to copy all properties
        return Object.assign(new MobileSettings(), dto);
    }
    /**
     * Maps from MobileSettings to MobileSettingsDTO.
     * @param domain MobileSettings object.
     * @returns MobileSettingsDTO object.
     */
    override mapTo(domain: MobileSettings): MobileSettingsDTO {
        // Since they have same properties, use spread operator to copy all properties
        return Object.assign(new MobileSettingsDTO(), domain);
    }
}
