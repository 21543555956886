import { MobileImagesJSON } from "../response/mobile-images.response";

/**
 * Represents a Mobile Images Data Transfer Object (DTO).
 */
export class MobileImagesDTO {
    name!: string;
    value!: string | number | boolean;
    type?: string;

    /**
     * Creates an instance of MobileImagesDTO.
     * @param mobileImagesJSON - The JSON object used to initialize the MobileImagesDTO.
     */
    constructor(mobileImagesJSON?: MobileImagesJSON) {
        if (mobileImagesJSON) {
            this.name = mobileImagesJSON.name;
            this.type = mobileImagesJSON.type;
            this.value = mobileImagesJSON.value;
        }
    }
}
