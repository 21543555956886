import { inject, Injectable } from "@angular/core";
import { SystemSettings } from "../../core/domain/system-settings.model";
import { DefaultMobileImagesSettings } from "../../default-settings/default-mobile-images.settings";
import { ConfigurationMobileImageKeys } from "../../../domain/enums/configuration-mobile-image-keys.enum";
import { Store } from "../../../base/store";
import { SystemSettingsRemoteRepository } from "../persistence/system-settings-remote.repository";
import { MobileSettings } from "../../core/domain/mobile-settings.model";
import { SystemSettingsLocalRepository } from "../persistence/system-settings-local.repository";
import { NetworkStatusService } from "../../../../shared/infrastructure/services/network-status.service";
import { DefaultDraftDialogSettings } from "../../default-settings/default-draft-dialog.settings";
@Injectable({
    providedIn: "root",
})
export class SystemSettingsStore extends Store<SystemSettings> {
    private systemSettingsRemoteRepository: SystemSettingsRemoteRepository =
        inject(SystemSettingsRemoteRepository);
    private systemSettingsLocalRepository: SystemSettingsLocalRepository =
        inject(SystemSettingsLocalRepository);
    private networkStatusService: NetworkStatusService =
        inject(NetworkStatusService);
    private _systemSettings: SystemSettings | null = null;
    private _loginTopImage!: string;
    private _mobileBannerImage!: string;

    /**
     * Get system settings from remote repository if online of not get from local repository. If online load fails, try to local from local
     * @returns SystemSettings[]
     */
    override async load(): Promise<SystemSettings[]> {
        if (this.networkStatusService.getCombinedOnlineStatus()) {
            try {
                this.logger.debug("Loading System settings from remote");
                this._systemSettings = await this.getRemoteSystemSettings();
            } catch (error) {
                this.logger.error(
                    "Failed to load System settings from remote",
                    error
                );

                this.logger.debug(
                    "Loading System settings from local as remote load failed"
                );
                this._systemSettings = await this.getLocalSystemSettings();
            }
        } else {
            this.logger.debug("Loading System settings from local");
            this._systemSettings = await this.getLocalSystemSettings();
        }

        return [this._systemSettings];
    }

    /**
     * Get System settings from Remote repository
     * @returns Promise<SystemSettings>
     */
    private async getRemoteSystemSettings(): Promise<SystemSettings> {
        return this.systemSettingsRemoteRepository.getSystemSettings();
    }

    /**
     * Get System settings from local repository
     * @returns Promise<SystemSettings>
     */
    private async getLocalSystemSettings(): Promise<SystemSettings> {
        const result =
            await this.systemSettingsLocalRepository.getSystemSettings();

        if (!result.length) {
            throw new Error("System settings not found in Indexed DB");
        }

        return result[0];
    }

    /**
     * Clear  systemSettings
     */
    override clear(): void {
        this._systemSettings = null;
    }

    /**
     * Method not implemented
     */
    protected override reset(): void {
        throw new Error("Method not implemented.");
    }

    /**
     * Get all SystemSettings
     * @returns  SystemSettings[]
     */
    override getAll(): SystemSettings[] {
        const systemSettings = this.getSystemSettings();
        return [systemSettings];
    }

    /**
     * Method not implemented
     */
    override getById(id: number): SystemSettings | undefined {
        throw new Error("Method not implemented.");
    }

    /**
     * Get System settings
     * @returns SystemSettings
     */
    getSystemSettings(): SystemSettings {
        if (!this._systemSettings) {
            throw new Error("System settings is not set");
        }

        return this._systemSettings;
    }

    /**
     * Get mobile settings
     * @returns MobileSettings
     */
    getMobileSettings(): MobileSettings {
        return this.getSystemSettings().mobileSettings;
    }

    /**
     * Returns mobile login top image if present if not return default value
     * @returns string
     */
    getLoginTopImage(): string {
        if (!this._loginTopImage) {
            const image = this.getSystemSettings().mobileImages.find(
                (x) => x.name === ConfigurationMobileImageKeys.MobileLoginTop
            );

            this._loginTopImage =
                (image?.value as string) ??
                DefaultMobileImagesSettings.loginTopImage;
        }

        return this._loginTopImage;
    }

    /**
     * Returns mobile banner image if present if not return default value
     * @returns string
     */
    getMobileBannerImage(): string {
        if (!this._mobileBannerImage) {
            const image = this.getSystemSettings().mobileImages.find(
                (x) => x.name === ConfigurationMobileImageKeys.MobileBannerImage
            );

            this._mobileBannerImage =
                (image?.value as string) ??
                DefaultMobileImagesSettings.bannerImage;
        }

        return this._mobileBannerImage;
    }

    getDraftDialogGapDays(): number {
        return (
            this.getSystemSettings().mobileSettings.draftsGapDays ??
            DefaultDraftDialogSettings.gapDays
        );
    }
}
