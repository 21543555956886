import { Injectable } from "@angular/core";
import { Mapper } from "../../../base/mapper";
import { GeneralSettingsMobile } from "../../core/domain/general-settings-mobile.model";
import { GeneralSettingsMobileDTO } from "../models/dto/general-settings-mobile.dto";

@Injectable({
    providedIn: "root",
})
/**
 * Maps between `GeneralSettingsMobileDTO` and `GeneralSettingsMobile` objects.
 */
export class GeneralSettingsMobileMapper extends Mapper<
    GeneralSettingsMobileDTO,
    GeneralSettingsMobile
> {
    /**
     * Maps from `GeneralSettingsMobileDTO` to `GeneralSettingsMobile`.
     * @param dto `GeneralSettingsMobileDTO` object.
     * @returns `GeneralSettingsMobile` object.
     */
    override mapFrom(dto: GeneralSettingsMobileDTO): GeneralSettingsMobile {
        return Object.assign(new GeneralSettingsMobile(), dto);
    }
    /**
     * Maps from `GeneralSettingsMobile` to `GeneralSettingsMobileDTO`.
     * @param domain `GeneralSettingsMobile` object.
     * @returns `GeneralSettingsMobileDTO` object.
     */
    override mapTo(domain: GeneralSettingsMobile): GeneralSettingsMobileDTO {
        return Object.assign(new GeneralSettingsMobileDTO(), domain);
    }
}
