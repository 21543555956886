import { Injectable } from "@angular/core";
import { Mapper } from "../../../base/mapper";
import { MobileImages } from "../../core/domain/mobile-images.model";
import { MobileImagesDTO } from "../models/dto/mobile-images.dto";

@Injectable({
    providedIn: "root",
})
/**
 * Maps between `MobileImagesDTO` and `MobileImages` objects.
 */
export class MobileImagesMapper extends Mapper<MobileImagesDTO, MobileImages> {
    /**
     * Maps from `MobileImagesDTO` to `MobileImages`.
     * @param dto `MobileImagesDTO` object.
     * @returns `MobileImages` object.
     */
    override mapFrom(dto: MobileImagesDTO): MobileImages {
        // Since they have same properties, use spread operator to copy all properties

        const model: MobileImages = {
            ...new MobileImages(),
            ...dto,
            value: dto.type?.startsWith("image/")
                ? `data:${dto.type};base64,${dto.value}`
                : dto.value,
        };

        return model;
    }
    /**
     * Maps from `MobileImages` to `MobileImagesDTO`.
     * @param domain `MobileImages` object.
     * @returns `MobileImagesDTO` object.
     */
    override mapTo(domain: MobileImages): MobileImagesDTO {
        // Since they have same properties, use spread operator to copy all properties

        const dto: MobileImagesDTO = {
            ...new MobileImagesDTO(),
            ...domain,
            value: domain.type?.startsWith("image/")
                ? (domain.value as string).replace(
                      `data:${domain.type};base64,`,
                      ""
                  )
                : domain.value,
        };

        return dto;
    }
}
