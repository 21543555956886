import { DateTimeFormatSettings } from "./date-time-format-settings.model";
import { EmailSettings } from "./email-settings.model";
import { GeneralSettingsMobile } from "./general-settings-mobile.model";
import { MobileImages } from "./mobile-images.model";
import { MobileSettings } from "./mobile-settings.model";

export class SystemSettings {
    emailSettings: EmailSettings = new EmailSettings();
    generalSettings: GeneralSettingsMobile = new GeneralSettingsMobile();
    mobileSettings: MobileSettings = new MobileSettings();
    showRedactedFieldsInPdf!: boolean; // TODO: This should be moved to appropriate system settings
    mobileImages: MobileImages[] = [];
    dateTimeFormat: DateTimeFormatSettings = new DateTimeFormatSettings();
    organizationId: number = 0;
}
