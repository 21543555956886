import { DateTimeFormatJSON } from "../response/date-time-format-settings.response";

/**
 * Represents a DateTimeFormatDTO object that holds the date and time format settings.
 */
export class DateTimeFormatDTO {
    /**
     * The date and time format for the front-end display.
     */
    dateTimeFormatFrontEnd: string = "";

    /**
     * The date format for the front-end display.
     */
    dateFormatFrontEnd: string = "";

    /**
     * The date and time format.
     */
    dateTimeFormat: string = "";

    /**
     * The date format.
     */
    dateFormat: string = "";

    /**
     * The organization's timezone.
     */
    organizationTimezone: string = "";

    /**
     * Creates a new instance of the DateTimeFormatDTO class.
     * @param dateTimeFormatJSON - Optional JSON object containing the date and time format settings.
     */
    constructor(dateTimeFormatJSON?: DateTimeFormatJSON) {
        if (dateTimeFormatJSON) {
            this.dateTimeFormatFrontEnd =
                dateTimeFormatJSON.dateTimeFormatFrontEnd;
            this.dateFormatFrontEnd = dateTimeFormatJSON.dateFormatFrontEnd;
            this.dateTimeFormat = dateTimeFormatJSON.dateTimeFormatSencha;
            this.dateFormat = dateTimeFormatJSON.dateFormatSencha;
            this.organizationTimezone = dateTimeFormatJSON.organizationTimezone;
        }
    }
}
