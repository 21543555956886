import { inject, Injectable } from "@angular/core";
import { Mapper } from "../../../base/mapper";
import { SystemSettings } from "../../core/domain/system-settings.model";
import { SystemSettingsDTO } from "../models/dto/system-settings.dto";
import { DateTimeFormatSettingsMapper } from "./date-time-format-settings.mapper";
import { GeneralSettingsMobileMapper } from "./general-settings-mobile.mapper";
import { MobileSettingsMapper } from "./mobile-settings.mapper";
import { EmailSettingsMapper } from "./email-settings.mapper";
import { MobileImagesMapper } from "./mobile-images.mapper";

@Injectable({
    providedIn: "root",
})
export class SystemSettingsMapper extends Mapper<
    SystemSettingsDTO,
    SystemSettings
> {
    private _dateTimeFormatSettingsMapper: DateTimeFormatSettingsMapper =
        inject(DateTimeFormatSettingsMapper);
    private _generalSettingsMobileMapper: GeneralSettingsMobileMapper = inject(
        GeneralSettingsMobileMapper
    );
    private _mobileSettingsMapper: MobileSettingsMapper =
        inject(MobileSettingsMapper);
    private _emailSettingsMapper: EmailSettingsMapper =
        inject(EmailSettingsMapper);
    private _mobileImagesMapper: MobileImagesMapper =
        inject(MobileImagesMapper);

    override mapFrom(dto: SystemSettingsDTO): SystemSettings {
        return Object.assign(new SystemSettings(), {
            dateTimeFormat: this._dateTimeFormatSettingsMapper.mapFrom(
                dto.dateTimeFormat
            ),
            generalSettings: this._generalSettingsMobileMapper.mapFrom(
                dto.generalSettings
            ),
            mobileSettings: this._mobileSettingsMapper.mapFrom(
                dto.mobileSettings
            ),
            emailSettings: this._emailSettingsMapper.mapFrom(dto.emailSettings),
            mobileImages: dto.mobileImages.map((mobileImage) =>
                this._mobileImagesMapper.mapFrom(mobileImage)
            ),
            organizationId: dto.organizationId,
            showRedactedFieldsInPdf: dto.showRedactedFieldsInPdf,
        });
    }
    override mapTo(domain: SystemSettings): SystemSettingsDTO {
        return new SystemSettingsDTO(
            this._emailSettingsMapper.mapTo(domain.emailSettings),
            this._generalSettingsMobileMapper.mapTo(domain.generalSettings),
            this._mobileSettingsMapper.mapTo(domain.mobileSettings),
            domain.mobileImages.map((image) =>
                this._mobileImagesMapper.mapTo(image)
            ),
            this._dateTimeFormatSettingsMapper.mapTo(domain.dateTimeFormat),
            domain.showRedactedFieldsInPdf,
            domain.organizationId
        );
    }
}
