//TODO: Switch these properties to enums so that we can have a list of options for the user to choose from.
export class DateTimeFormatSettings {
    /**
     * The date and time format for the front-end display.
     */
    dateTimeFormatFrontEnd: string = "";

    /**
     * The date format for the front-end display.
     */
    dateFormatFrontEnd: string = "";

    /**
     * The date and time format.
     */
    dateTimeFormat: string = "";

    /**
     * The date format.
     */
    dateFormat: string = "";

    /**
     * The organization's timezone.
     */
    organizationTimezone: string = "";
}
