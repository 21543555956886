import { Injectable } from "@angular/core";
import { Mapper } from "../../../base/mapper";
import { EmailSettings } from "../../core/domain/email-settings.model";
import { EmailSettingsDTO } from "../models/dto/email-settings.dto";

@Injectable({
    providedIn: "root",
})
/**
 * Maps between `EmailSettingsDTO` and `EmailSettings` objects.
 */
export class EmailSettingsMapper extends Mapper<
    EmailSettingsDTO,
    EmailSettings
> {
    /**
     * Maps from `EmailSettingsDTO` to `EmailSettings`.
     * @param dto `EmailSettingsDTO` object.
     * @returns `EmailSettings` object.
     */
    mapFrom(dto: EmailSettingsDTO): EmailSettings {
        // Since they have same properties, use spread operator to copy all properties
        return Object.assign(new EmailSettings(), { ...dto });
    }
    /**
     * Maps from `EmailSettings` to `EmailSettingsDTO`.
     * @param domain `EmailSettings` object.
     * @returns `EmailSettingsDTO` object.
     */
    mapTo(domain: EmailSettings): EmailSettingsDTO {
        // Since they have same properties, use spread operator to copy all properties
        return Object.assign(new EmailSettingsDTO(), { ...domain });
    }
}
