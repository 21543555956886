/**
 * Represents the data model for system settings.
 */
import { DateTimeFormatDTO } from "./date-time-format-settings.dto";
import { EmailSettingsDTO } from "./email-settings.dto";
import { GeneralSettingsMobileDTO } from "./general-settings-mobile.dto";
import { MobileImagesDTO } from "./mobile-images.dto";
import { MobileSettingsDTO } from "./mobile-settings.dto";

export class SystemSettingsDTO {
    emailSettings!: EmailSettingsDTO;
    generalSettings!: GeneralSettingsMobileDTO;
    mobileSettings!: MobileSettingsDTO;
    showRedactedFieldsInPdf!: boolean;
    mobileImages!: MobileImagesDTO[];
    dateTimeFormat!: DateTimeFormatDTO;
    organizationId!: number;

    /**
     * Creates an instance of SystemSettingsDTO.
     * @param emailSettings - The email settings.
     * @param generalSettings - The general settings.
     * @param mobileSettings - The mobile settings.
     * @param mobileImages - The mobile images.
     * @param dateTimeFormat - The date time format.
     * @param showRedactedFieldsInPdf - Indicates whether to show redacted fields in PDF.
     * @param organizationId - The organization ID.
     */
    constructor(
        emailSettings: EmailSettingsDTO,
        generalSettings: GeneralSettingsMobileDTO,
        mobileSettings: MobileSettingsDTO,
        mobileImages: MobileImagesDTO[],
        dateTimeFormat: DateTimeFormatDTO,
        showRedactedFieldsInPdf: boolean,
        organizationId: number
    ) {
        this.emailSettings = emailSettings;
        this.generalSettings = generalSettings;
        this.mobileSettings = mobileSettings;
        this.showRedactedFieldsInPdf = showRedactedFieldsInPdf;
        this.mobileImages = mobileImages;
        this.dateTimeFormat = dateTimeFormat;
        this.organizationId = organizationId;
    }
}
